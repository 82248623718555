<template>
  <c-button
    background-color="transparent"
    display="flex"
    justify-content="space-between"
    border-radius="none"
    padding="28px 25px"
    font-size="18px"
    font-weight="500"
    color="primary.400"
    border-top="1px solid #e5e7eb"
    @click="handleClick"
  >
    <slot />

    <c-flex
      height="20px"
      width="20px"
      :background-color="isChecked ? 'primary.400' : 'white'"
      border="2px solid"
      :border-color="isChecked ? 'primary.400' : '#e5e7eb'"
      border-radius="2px"
      justify-content="center"
      align-items="center"
    >
      <inline-svg
        :src="require('@/assets/icons/icon-check.svg')"
        fill="white"
        height="10px"
        width="10px"
      />
    </c-flex>
  </c-button>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    isChecked: Boolean,
    isDisabled: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('change', !this.isChecked)
    },
  },
}
</script>
