<template>
  <c-button
    v-bind="$props"
    role="radio"
    :aria-checked="isChecked"
    background-color="transparent"
    display="flex"
    justify-content="space-between"
    border-radius="none"
    padding="28px 25px"
    font-size="18px"
    font-weight="500"
    color="primary.400"
    border-top="1px solid #e5e7eb"
  >
    <slot />
    <c-radio
      size="lg"
      :is-checked="isChecked"
      variant-color="primary"
    />
  </c-button>
</template>

<script>
export default {
  name: 'CustomRadio',
  props: {
    isChecked: Boolean,
    isDisabled: Boolean,
    value: {
      type: [String, Number],
      default: '',
    },
    mx: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>
