var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', _vm._b({
    attrs: {
      "role": "radio",
      "aria-checked": _vm.isChecked,
      "background-color": "transparent",
      "display": "flex",
      "justify-content": "space-between",
      "border-radius": "none",
      "padding": "28px 25px",
      "font-size": "18px",
      "font-weight": "500",
      "color": "primary.400",
      "border-top": "1px solid #e5e7eb"
    }
  }, 'c-button', _vm.$props, false), [_vm._t("default"), _c('c-radio', {
    attrs: {
      "size": "lg",
      "is-checked": _vm.isChecked,
      "variant-color": "primary"
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }