var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', {
    attrs: {
      "background-color": "transparent",
      "display": "flex",
      "justify-content": "space-between",
      "border-radius": "none",
      "padding": "28px 25px",
      "font-size": "18px",
      "font-weight": "500",
      "color": "primary.400",
      "border-top": "1px solid #e5e7eb"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._t("default"), _c('c-flex', {
    attrs: {
      "height": "20px",
      "width": "20px",
      "background-color": _vm.isChecked ? 'primary.400' : 'white',
      "border": "2px solid",
      "border-color": _vm.isChecked ? 'primary.400' : '#e5e7eb',
      "border-radius": "2px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-check.svg'),
      "fill": "white",
      "height": "10px",
      "width": "10px"
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }